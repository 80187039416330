
import { DivisionCircleSlider } from "./views/DivisionCircleSlider";
import { WindowWatcher } from "../umadash.js/util/WindowWatcher";
import { NewsBoard } from "./views/NewsBoard";
import { KeyboardWatcher, KeyboardEvent } from "../umadash.js/util/KeyboardWatcher";
const $ = jQuery;

export class EntryIndex {

    private resizeHandler: () => void;
    private scrollHandler: () => void;
    private divisionCircleSlider: DivisionCircleSlider;
    private newsBoard: NewsBoard;


    constructor() {

        this.resizeHandler = this.resize.bind(this);

        this.divisionCircleSlider = new DivisionCircleSlider($('#js-divisionCircleSlider'));
        this.newsBoard = new NewsBoard($('#js-newsBoard'), 5000);
    }

    public run(): void {

        this.divisionCircleSlider.start();
        this.newsBoard.start();

        WindowWatcher.getInstance().addEventListener(WindowWatcher.Resize, this.resizeHandler);

        this.resize();

        KeyboardWatcher.getInstance().start();
        WindowWatcher.getInstance().start();
    }

    public resize(): void {
        this.divisionCircleSlider.resize();
    }



}