import { View } from "../../umadash.js/view/View";
import { Command } from "../../umadash.js/command/Command";
import { CommandUtil } from "../../umadash.js/util/CommandUtil";
import { Inputer } from "./Inputer";
import { Func } from "../../umadash.js/command/Func";
import { DoTween } from "../../umadash.js/command/DoTween";
import { Easing } from "../../umadash.js/tween/Easing";
import { Wait } from "../../umadash.js/command/Wait";
import { DeviceUtil } from "../../umadash.js/util/DeviceUtil";

const $ = jQuery;

export class DivisionCircle extends View<JQuery> {

    private static Friction: number = .8;
    private static Spring: number = .12;

    private $elm: JQuery;
    private mouseEvent: boolean;

    private $core: JQuery;
    private $circle: JQuery;
    private $img: JQuery;
    private $titleJp: JQuery;
    private $titleEn: JQuery;
    private $anchor: JQuery;

    private titleInputer: Inputer;
    private enTitleInputer: Inputer;

    private scale: number;
    private illustScale: number;

    private updateHandler: () => void;
    private mouseX: number;
    private mouseY: number;
    private targetX: number;
    private targetY: number;
    private centerX: number;
    private centerY: number;
    private radius: number;

    private opacity: number;


    constructor($elm: JQuery, mouseEvent: boolean = false) {
        super();

        this.$elm = $elm;
        this.mouseEvent = mouseEvent;


        this.$core = $elm.find('.divisionCircle__core');
        this.$circle = $elm.find('.divisionCircle__circle');
        this.$img = this.$elm.find('.divisionCircle__illust');
        this.$titleJp = $elm.find('.divisionCircle__titleJp');
        this.$titleEn = $elm.find('.divisionCircle__titleEn');
        this.$anchor = $elm.find('.divisionCircle__anchor');

        this.titleInputer = new Inputer(this.$titleJp);
        this.enTitleInputer = new Inputer(this.$titleEn);



        this.scale = 1.0;
    }

    private scaleDownCommand: Command;
    private scaleUpCommand: Command;

    private cancelScaleCommand(): void {
        if (this.scaleDownCommand) {
            this.scaleDownCommand.interrupt();
            this.scaleDownCommand = null;
        }

        if (this.scaleUpCommand) {
            this.scaleUpCommand.interrupt();
            this.scaleUpCommand = null;
        }
    }

    public ready(): void {
        this.$elm.off('mouseenter').off('mouseleave');
        if (!this.mouseEvent || !DeviceUtil.isDesktop()) return;

        this.$elm.on('mouseenter', () => {
            this.cancelScaleCommand();
            this.scaleDownCommand = this.getScaleDownCommand(true);
        });

        this.$elm.on('mouseleave', () => {
            this.cancelScaleCommand();
            this.scaleUpCommand = this.getScaleUpCommand(true);
        });
    }

    private getScaleDownCommand(execute: boolean): Command {
        return CommandUtil.serial([
            new DoTween(this, { scale: 1.1 }, null, 1000, Easing.easeOutQuart, null, () => {
                this.$elm.css({
                    transform: `translate(-50%, -50%) scale(${this.scale})`
                });
            })
        ], execute);
    }

    private getScaleUpCommand(execute: boolean): Command {
        return CommandUtil.serial([
            new DoTween(this, { scale: 1.0 }, null, 240, Easing.easeOutBack, null, () => {
                this.$elm.css({
                    transform: `translate(-50%, -50%) scale(${this.scale})`
                });
            })
        ], execute);
    }

    public resize(): void {
        this.centerX = this.$elm.width() / 2;
        this.centerY = this.$elm.height() / 2;
        this.radius = this.centerX;

        this.ready();
    }

    public setup(illust: string, title: string, enTitle: string, color: string, url: string): void {
        this.$circle.css({
            'background-color': `${color}`
        });
        this.titleInputer.setTargetText(title);
        this.enTitleInputer.setTargetText(enTitle);
        this.$img.attr('src', illust);
        this.$anchor.attr('href', url);
    }

    protected getShowCommand(execute: boolean): Command {
        return CommandUtil.serial([
            new Func(() => {
                this.$circle.css({
                    transform: `scale(0)`
                });
                this.$img.css({
                    transform: `translate(-50%, -50%) scale(0)`,
                    opacity: 0,
                });

                this.scale = .5;
                this.illustScale = .8;
                this.opacity = 0;
            }),
            new DoTween(this, { scale: 1.0 }, null, 600, Easing.easeOutBack, null,
                () => {
                    this.$circle.css({
                        transform: `scale(${this.scale})`
                    });
                }
            ),
            new Wait(.24),
            new DoTween(this, { illustScale: 1., opacity: 1.0 }, null, 400, Easing.easeOutBack, null,
                () => {
                    this.$img.css({
                        opacity: this.opacity,
                        transform: `translate(-50%, -50%) scale(${this.illustScale})`
                    });
                }
            ),
            new Wait(.2),
            new Func(() => {
                this.titleInputer.start();
                this.enTitleInputer.start();
            })
        ], execute);
    }

    protected getHideCommand(execute: boolean): Command {
        return CommandUtil.serial([
            new Func(() => {
                this.titleInputer.setTargetText('');
                this.enTitleInputer.setTargetText('');
                this.titleInputer.start();
                this.enTitleInputer.start();
            }),
            new DoTween(this, { illustScale: .5, opacity: 0 }, null, 400, Easing.easeInBack, null,
                () => {
                    this.$img.css({
                        opacity: this.opacity,
                        transform: `translate(-50%, -50%) scale(${this.illustScale})`
                    });
                }
            ),
            new DoTween(this, { scale: 0 }, null, 600, Easing.easeInBack, null,
                () => {
                    this.$circle.css({
                        transform: `scale(${this.scale})`
                    });
                }
            ),
        ], execute);
    }
}