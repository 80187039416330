import { EventDispatcher } from "../../umadash.js/event/EventDispatcher";
import Event from "../../umadash.js/event/Event";

export class Inputer extends EventDispatcher {

    public static Complete: string = 'complete';

    private $elm: JQuery;
    private updateHandler: () => void;
    private request: any;

    private prevText: string;
    private targetText: string;
    private count: number;

    constructor($elm: JQuery) {
        super();

        this.$elm = $elm;

        this.updateHandler = this.update.bind(this);
    }

    public start(): void {
        this.count = 0;
        this.prevText = this.$elm.text();
        this.$elm.text('');

        this.stop();
        this.update();
    }

    public stop(): void {
        if (this.request) {
            cancelAnimationFrame(this.request);
            this.request = null;
        }
    }

    public setTargetText(text: string): void {
        this.targetText = text;
    }

    private update(): void {
        this.request = window.requestAnimationFrame(this.updateHandler);
        const index: number = Math.floor(++this.count >> 1);
        const front: string = this.targetText.slice(0, index)

        if (index === this.targetText.length) {
            this.$elm.text(`${front}`);
            this.stop();

            this.dispatchEvent(new Event(Inputer.Complete));
        }
        else {
            this.$elm.text(`${front}|`);
        }
    }
}