import { DivisionCircle } from "./DivisionCircle";
import { CommandUtil } from "../../umadash.js/util/CommandUtil";
import { Func } from "../../umadash.js/command/Func";
import { Wait } from "../../umadash.js/command/Wait";

export class DivisionCircleSlider {

    private $elm: JQuery;


    private illusts: string[];
    private titles: string[];
    private enTitles: string[];
    private colors: string[];
    private urls: string[];
    private length: number;

    private currentIndex: number;

    private timer: any;

    private completeHandler: () => void;
    private hasLoaded: boolean;
    private hasPassed: boolean;

    private divisionCircle: DivisionCircle;


    constructor($elm: JQuery) {

        this.$elm = $elm;

        this.illusts = $elm.attr('data-illusts').split(',');
        this.titles = $elm.attr('data-jp_titles').split(',');
        this.enTitles = $elm.attr('data-en_titles').split(',');
        this.colors = $elm.attr('data-colors').split(',');
        this.urls = $elm.attr('data-urls').split(',');
        this.currentIndex = -1;
        this.length = this.illusts.length;

        this.hasLoaded = false;
        this.hasPassed = false;

        this.completeHandler = this.onComplete.bind(this);

        this.divisionCircle = new DivisionCircle($('#js-divisionCircle'));
    }

    public resize(): void {
        this.divisionCircle.resize();
    }

    public start(): void {
        if (this.length <= 1) return;

        const src = this.illusts[0];
        this.loadImage(src, () => {
            this.next();
            this.divisionCircle.ready();
        })
    }

    private onComplete(): void {
        if (!this.hasLoaded || !this.hasPassed) return;


        CommandUtil.serial([
            new Func(() => {
                this.divisionCircle.hide();
            }),
            new Wait(.8),
            new Func(() => {
                this.next();
            }),
        ], true);
    }

    private next(): void {

        this.hasLoaded = false;
        this.hasPassed = false;

        let nextIndex: number = this.currentIndex + 1;
        nextIndex = (nextIndex >= this.length) ? 0 : nextIndex;
        this.loadImage(this.illusts[nextIndex], () => {
            this.hasLoaded = true;
            this.completeHandler();
        });

        this.startTimer();
        this.currentIndex = nextIndex;

        // セットアップ
        const illust: string = this.illusts[nextIndex];
        const title: string = this.titles[nextIndex];
        const enTitle: string = this.enTitles[nextIndex];
        const color: string = this.colors[nextIndex];
        const url: string = this.urls[nextIndex];
        this.divisionCircle.setup(illust, title, enTitle, color, url);

        // アニメーション再生
        this.divisionCircle.show();
    }

    private startTimer(): void {
        this.stopTimer();
        this.timer = setTimeout(() => {
            this.hasPassed = true;
            this.completeHandler();
        }, 5000);
    }

    private stopTimer(): void {
        if (this.timer) {
            clearTimeout(this.timer);
            this.timer = null;
        }
    }

    private loadImage(url: string, onComplete: () => void): void {
        const image: HTMLImageElement = new Image();
        image.onload = onComplete;
        image.src = url;
    }

}