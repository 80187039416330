export class NewsBoard {

    private $el: JQuery;
    private interval: number;

    private $img: JQuery;
    private $anchor: JQuery;
    private $items: JQuery;
    private length: number;

    private currentIndex: number;
    private hasLoaded: boolean;
    private hasPassed: boolean;
    private timer: any;

    constructor($el: JQuery, interval: number) {
        this.$el = $el;
        this.interval = interval;


        this.$img = $el.find('.newsBoard__left');
        this.$anchor = this.$img.find('a');
        this.$items = $el.find('.newsBoard__right').children();
        this.length = this.$items.length;

        this.currentIndex = -1;
        this.hasLoaded = false;
        this.hasPassed = false;

        this.$items.on('mouseenter', (e) => {
            const $target = $(e.currentTarget);
            const index: number = parseInt($target.attr('data-index'), 10);
            this.changeAt(index);
        });
    }

    public start(): void {
        this.stop();

        if (this.length > 0) {
            this.next();
        }
    }

    public stop(): void {
        if (this.timer) {
            clearTimeout(this.timer);
            this.timer = null;
        }
    }

    private next(): void {
        let nextIndex: number = this.currentIndex + 1;
        if (nextIndex >= this.length) nextIndex = 0;
        this.changeAt(nextIndex);
    }

    private changeAt(index: number): void {

        const prevItem: JQuery = this.$items.eq(this.currentIndex);
        const nextItem: JQuery = this.$items.eq(index);
        prevItem.removeClass('current');
        nextItem.addClass('current');

        const url = nextItem.attr('data-url');
        const img = nextItem.attr('data-img');

        if (img) {
            this.$img.css('background-image', `url(${img})`);
        }
        else {
            this.$img.css('background-image', '');
        }

        if (url) {
            this.$anchor.attr('href', url);
        }

        this.currentIndex = index;

        this.stop();
        this.timer = setTimeout(() => {
            this.next();
        }, this.interval);
    }

    private loadImage(url: string, onComplete: () => void): void {
        const image: HTMLImageElement = new Image();
        image.onload = onComplete;
        image.src = url;
    }
}